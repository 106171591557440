<template>
  <div>
    <el-row :gutter="20">
      <el-col
        style="margin-bottom: 20px; height: 500px"
        :xs="24"
        :sm="24"
        :lg="8"
        v-for="i in 3"
        :key="i"
        :span="8"
      >
        <PieChart
          class="echart_card"
          :id="'test' + i"
          height="100%"
          width="100%"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PieChart from '@/components/Charts/PieChart'
export default {
  components: { PieChart },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.echart_card {
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
